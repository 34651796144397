@import '@styles/variables.module.scss';
$pro-header-hover-bg: rgba(0, 0, 0, 0.025);

span.environmentTagText {
  color: #1976d2;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.015em;
}

.singleLevelListItemText {
  color: $black;
  margin-left: 8px;
  span {
    font-size: 14px;
    font-weight: 450;
  }
}

.header {
  position: relative;
  height: $layout-header-height;
  padding: 0;
  background: #fff;
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
  display: flex;
}
.left {
  display: flex;
  align-items: center;
}
.logo {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 16px;
  font-size: 20px;
  line-height: $layout-header-height;
  vertical-align: top;
  cursor: pointer;
  img {
    display: inline-block;
    vertical-align: middle;
  }
}
.menu {
  :global(.anticon) {
    margin-right: 8px;
  }
  :global(.ant-dropdown-menu-item) {
    min-width: 160px;
  }
}
.trigger {
  height: $layout-header-height;
  padding: 0 24px;
  font-size: 20px;
  cursor: pointer;
  transition:
    all 0.3s,
    padding 0s;
  &:hover {
    background: $pro-header-hover-bg;
  }
}
.sessionExpiry {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.right {
  margin-left: auto;
  height: 100%;
  overflow: hidden;
  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 12px;
    cursor: pointer;
    transition: all 0.3s;
    > i {
      color: $text-color;
      vertical-align: middle;
    }
    .name {
      color: $black;
      font-size: 14px;
      font-weight: 500;
    }
    .dropdown {
      z-index: 999;
    }
    &:hover {
      background: $pro-header-hover-bg;
    }
    &:global(.opened) {
      background: $pro-header-hover-bg;
    }
  }
  .search {
    padding: 0 12px;
    &:hover {
      background: transparent;
    }
  }
  .account {
    .avatar {
      color: $black;
      margin-right: 8px;
    }
  }
}
.dark {
  height: $layout-header-height;
  .action {
    color: rgb(255 255 255 / 85%);
    > i {
      color: rgb(255 255 255 / 85%);
    }
    &:hover,
    &:global(.opened) {
      background: $primary-color;
    }
    :global(.ant-badge) {
      color: rgb(255 255 255 / 85%);
    }
  }
}

.menuChildrenContainer {
  background: rgba(25, 118, 210, 0.04);
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

@media only screen and (max-width: $screen-md) {
  .header {
    :global(.ant-divider-vertical) {
      vertical-align: unset;
    }
    .name {
      display: none;
    }
    i.trigger {
      padding: 22px 12px;
    }
    .logo {
      position: relative;
      padding-right: 12px;
      padding-left: 12px;
    }
    .right {
      position: absolute;
      top: 0;
      right: 12px;
      background: #fff;
      .account {
        .avatar {
          margin-right: 0;
        }
      }
    }
  }
}
