/**
* This file is meant to host antd less variables.
* It is preferable to define theming variables here than in component files.
* We are migrating to material so this file will be removed eventually, but it
* will make our life easier when actually theming migration happens.
*
*/

// Color Definitions
$black: #000;
$white: #fff;
$grey: #828282;
$blue-base: #1890ff;
$blue-6: $blue-base; // Primary color
$blue-7: #0958d9;

// Primary and Text Colors
$primary-color: $blue-6;
$text-color: fade($black, 85%);
$text-color-secondary: fade($black, 45%);
$heading-color: fade($black, 85%);
$disabled-color: fade($black, 25%);
$component-background: $white;
$background-color-light: #fafafa;

// Success, Warning, Error and Info Colors
$error-color: #eb5757;
$error-color-hover: lighten($error-color, 10%);
$success-color: #27ae60;
$success-color-hover: lighten($success-color, 10%);

// Table Styles
$table-header-bg: #1166c8;
$table-header-color: #f2f2f2;
$table-header-sort-active-bg: $blue-6;
$table-header-sort-bg: $blue-7;
$drawer-bg: #001529;

// Layout and Spacing
$layout-header-height: 64px;
$menu-collapsed-width: 70px;

// Borders and Dividers
$border-width-base: 1px;
$border-style-base: solid;
$border-color-split: hsl(0, 0, 94%);

// Typography
$font-size-base: 14px;

// Animation and Transition
$ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);

// Media queries breakpoints
// $screen-xs and $screen-xs-min is not used in Grid
// smallest break point is $screen-md
$screen-xs: 480px;
$screen-xs-min: $screen-xs;
// 👆 Extra small screen / phone

// 👇 Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;

// provide a maximum
$screen-xs-max: ($screen-sm-min - 1px);
$screen-sm-max: ($screen-md-min - 1px);
$screen-md-max: ($screen-lg-min - 1px);
$screen-lg-max: ($screen-xl-min - 1px);
$screen-xl-max: ($screen-xxl-min - 1px);

:export {
  primaryColor: $blue-6;
  errorColor: $error-color;
  successColor: $success-color;
}
